.distributePage-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: white;
}

.distributePage-container .distributePage {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    /*  */
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    background-color: rgba(255, 255, 255, 0.8);
}

.distributePage .distributePageContent {
    position: relative;
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    box-shadow: none !important;
    display: grid;
    place-items: center;
    color: black;
}

.distributePage .distributePageContent-hstack {
    position: relative;
    display: flex;
    flex-direction: row;
}

.distributePage .distributePageContent-vstack {
    position: relative;
    display: flex;
    flex-direction: column;
}

.distributePage .contentImage-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-left: 60px;
    width: 500px;
    font-size: 14px;
    color: rgb(73, 73, 73);
}

.distributePage .contentImage {
    position: relative;
    width: 500px;
    height: 500px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0 0 10px 2px rgb(0, 0, 0, 0.1);
}

.contentImage-container p {
    margin-top: 8px;
}

.contentImage-container p i {
    font-style: italic;
}

.distributePageContent-vstack {
    margin-left: 30px;
    margin-right: 60px;
}

.distributePage .distributePageContent-vstack .distributePageTitle {
    margin-bottom: 24px;
}

.distributePage .distributePageContent-vstack .distributePageTitle h1 {
    font-size: 48px;
    margin-bottom: 12px;
    color: rgb(144, 144, 144);
}

.distributePage .distributePageContent-vstack .distributePageTitle h2 {
    font-size: 30px;
}

.distributePage .distributePageContent-vstack p {
    position: relative;
    font-size: 19px;
    line-height: 1.7em;
}