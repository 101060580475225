.dashboard-page-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: rgb(0, 23, 54);
    --side-menu-width: 150px;
    --top-spacing: 20px;
}
/* rgba(32, 147, 255, 0.4)  */

/* Side Menu */
.dashboard-side-menu {
    position: relative;
    width: var(--side-menu-width);
    height: 100vh;
    background-color: rgb(0, 23, 54);
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Content View */
.dashboard-content-view {
    position: relative;
    width: calc(100vw - var(--side-menu-width));
    height: calc(100vh - ( 2 * var(--top-spacing)));
    margin-top: var(--top-spacing);
    border-radius: 24px;
    background-color: white;
    overflow: hidden;
}
