
.session .header-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    height: var(--header-height);
    /* background: linear-gradient(black, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.15)); */
    background-color: rgba(255, 255, 255, 0.75);
    background-color: white;
    z-index: 100;
    /* box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1); */
    border-radius: 4px;
  }
  
.session .logo-and-app-title {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
  }
  
.session .logo-container {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    margin-left: 60px;
    /* background-color: gray; */
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
    /* box-shadow: 0 0 3px 0.5px rgba(0, 0, 0, 0.15); */
  }

.session .logo-container img {
    position: relative;
    height: 100%;
    width: 100%;
  }
  
.session .appliction-title {
    color: rgba(0, 0, 0, 0.90);
    font-size: 20px;
    margin-left: 8px;
    font-family: 'barlow', cursive;
    font-weight: 700;
    letter-spacing: 0.1px;
    /* text-shadow: 0px 0.5px rgb(0, 0, 0, 0.5); */
  }
  
.session .header-nav-options {
    position: relative;
    right: 40px;
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-top: 20px;
    margin-left: auto;
  }
  
.session .header-nav-options li {
    margin-left: 30px;
    color: black;
    font-size: 14px;
    /* text-shadow: 0px 0.5px rgb(0, 0, 0, 0.5); */
    /* font-family: 'comfortaa-bold', cursive; */
  }
  
.session .header-nav-options li:hover {
    text-decoration: underline;
  }

.session .header-buttons {
    font-size: 12px;
    height: fit-content;
    width: fit-content;
    font-weight: 600;
    margin-left: 20px;
    background: black;
    color: white;
    padding: 8px 12px;
    border-radius: 20px;
    margin-right: 60px;
  }

.session .header-buttons:first-of-type {
    margin: 8px;
  }