/* Notes Main Container */
.controller-main-notes-container {
    position: absolute;
    top: 0;
    height: 100vh;
    --width-notes-container: 85vw;
    --notes-container-default-right-position: calc( (-1 * (var(--width-notes-container)))); 
    right: calc(var(--notes-container-default-right-position) + 20px); 
    width: var(--width-notes-container);
    /*  */
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
    z-index: 10000000000000;
    --options-container-height: 50px;
    transition: 0.5s ease-in-out;
}

.controller-main-notes-container.show {
    right: 0 !important;
}

.toggle-notes-container {
    position: absolute;
    top: 140px;
    left: 0;
    background-color: var(--app-blue);
    padding: 8px 20px;
    transform: translateX(-100%);
    z-index: 1000000000000000000000;
    width: auto;
    height: auto;
    border-radius: 2px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: -4px 0 8px 2px rgba(0, 0, 0, 0.1);
}

.toggle-notes-button-text { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
    font-family: 'barlow';
    font-weight: 600;
}

/* Notes Option Controller */
.notes-controller-option-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--options-container-height);
    background-color: light;
    z-index: 100;
    display: flex;
    flex-direction: row;
    box-shadow: 0 0 3px 0.5px rgba(0, 0, 0, 0.15);
    padding: 0;
    margin: 0;
    background-color: white;
}

.notes-options {
    width: fit-content;
    height: var(--options-container-height);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.notes-options h4 {
    margin-left: 20px;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 12px;
}

.notes-options.user-notes-button-container.active h4,
.notes-options.shared-user-notes-button-container.active h4 {
    background-color: #2093ff86;
}

.shared-notes-scrollable-container {
    margin: 0;
    padding: 0;
}

/* Notes Content View */
.user-shared-notes-container {
    position: absolute;
    top: var(--options-container-height);
    left: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.shared-notes-container,
.user-notes-container {
    position: relative;
    width: 0;
    height: calc(100vh - var(--options-container-height));
    transition: 0.7s ease-in-out;
}

.shared-notes-container.active {
    width: 100%;
}

.user-notes-container.active {
    width: 100%;
}
