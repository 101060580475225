.user-notes-container-content {
    position: relative;
    width: 100%;
    height: calc(100vh - var(--options-container-height));
    overflow: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.user-notes-scrollable-container {
    position: relative;
    height: 100%;
    width: 100%;
}