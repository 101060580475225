.home-view {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 30px;
}

.home-view-hstack {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
}

/* Header Card */
/* Header Card */
/* Header Card */
.home-view .home-header-carder {
    position: relative;
    width: calc(100% - 60px);
    height: 150px;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 0 8px 4px rgba( 0, 0, 0, 0.07 );
    border: 2px solid rgba(0, 0, 0, 0.08);
}

.home-view .header-title-desciption-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 30px;
}

.home-view .view-title {
    position: relative;
    margin-bottom: 12px;
}




/* course-item */
.home-view .courses {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.home-view .courses-container-title {
    position: relative;
    font-size: 22px;
}

.home-view .courses-container {
    position: relative;
    margin-top: 16px;
}

.home-view .course-item {
    position: relative;
    width: 400px;
    height: 300px;
    background: linear-gradient(rgb(70, 70, 70), rgb(30, 30, 30) ,black);
    border-radius: 12px;
    transition: 0.3s;
}

.home-view .course-item:hover {
    box-shadow: rgba(0, 0, 0, 24%) 0px 0px 40px -8px, rgba(0, 0, 0, 20%) 0px 0px 25px -16px;
    transform: scale3d(1.03, 1.03, 1.08) translate3d(0.1rem, -0.25rem, 20rem);
}

.home-view .course-item-content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    color: white;
}

.home-view .course-item-content h4 {
    font-size: 20px;
    margin-bottom: 8px;
}

.home-view .course-item-content h6 {
    font-size: 14px;
    margin-bottom: 16px;
    opacity: 0.75;
}

.home-view .recording-ui-home {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1000000;
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    animation-name: recording-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    opacity: 0.75;
}

@keyframes recording-animation {
    0%   {
        transform: scale(100%);
    }

    50%  {
        transform: scale(50%)
    }

    100% {
        transform: scale(100%);
    }
}

/* colors-ui colors-ui colors-ui */
/* colors-ui colors-ui colors-ui */
/* colors-ui colors-ui colors-ui */
/* colors-ui colors-ui colors-ui */
/* colors-ui colors-ui colors-ui */
/* colors-ui colors-ui colors-ui */
/* colors-ui colors-ui colors-ui */
.home-view .colors-ui-container {
    position: relative;
    z-index: 0;
    filter: blur(50px);
    border-radius: 20px;
    height: 100%;
    width: 100%;
    background-color: white;
    overflow: hidden;
}

.home-view .top-component {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
}