
.session .main-functionality {
    position: relative;
    width: 100vw;
    height: calc(100vh - var(--header-height));
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.session .course-title-lecture-title {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 8px;
    background-color: black;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.session .course-title-lecture-title .course-title-container ,
.session .course-title-lecture-title .lecture-title-container {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin-left: 20px;
}

.session .course-title-lecture-title .course-title-container h2 {
    font-size: 14px;
    font-weight: 600;
    color: white;
}

.session .course-title-lecture-title .course-title-lecture-divider {
    margin-left: 20px;
    font-size: 20px;
    font-weight: 600;
    color: white;
}

.session .course-title-lecture-title .lecture-title-container {
    margin-left: 20px;
}

.session .course-title-lecture-title .lecture-title-container h3 {
    font-size: 12px;
    font-weight: 600;
    color: gray;
}

.session .main-functionality-h-stack {
    position: relative;
    width: 100vw;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.session .horizontal-container{
    position: relative;
    height: 100%;
    width: 33%;
}

/* Session */ /* Session */ /* Session */ /* Session */ /* Session */ /* Session */ /* Session */
/* Session */ /* Session */ /* Session */ /* Session */ /* Session */ /* Session */ /* Session */
/* Session */ /* Session */ /* Session */ /* Session */ /* Session */ /* Session */ /* Session */
.session .session-code-ui {
    position: relative;
    height: 100%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-left: auto;
    margin-right: 40px;
    border-radius: 40px;
    overflow: hidden;
    height: 34px;
}

.session .session-code-ui .session-container {
    width: fit-content;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:#fed11b41;
    padding: 30px 20px;
    border-radius: 14px;
}

.session .session-key-title {
    font-size: 14px;
}

.session .session-key {
    margin-left: 16px;
    font-size: 14px;
}

/* Questions */ /* Questions */ /* Questions */ /* Questions */ /* Questions */ /* Questions */
/* Questions */ /* Questions */ /* Questions */ /* Questions */ /* Questions */ /* Questions */
/* Questions */ /* Questions */ /* Questions */ /* Questions */ /* Questions */ /* Questions */

.session .chat-ui {
    --chat-width : 67vw;
    --question-text-are-height: 200px;
    width: var(--chat-width);
    height: calc(100%);
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 0;
    padding: 0;
}

.session .chat-ui .questions-container {
    position: relative;
    height: 100%;
    margin-top: auto;
}

.session .questions-vstack-item-container {
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100% - var(--question-text-are-height));
}

.session .chat-ui .questions-container .questions-status {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: black;
    border-bottom-right-radius: 15px;
}

.session .unanswered-question-container,
.session .answered-question-container {
    position: absolute;
    height: 100%;
    width: 50%;
    display: grid;
    place-items: center;
    color: white;
    transition: 0.2s ease-in-out;
}

.session .unanswered-question-container.selected, 
.session .answered-question-container.selected,
.session .unanswered-question-container:hover,
.session .answered-question-container:hover {
    background-color: rgb(30, 30, 30);
}

.session .answered-question-container,
.session .answered-question-container:hover {
    border-bottom-right-radius: 12px;
}

.session .unanswered-question-container h4,
.session .answered-question-container h4 {
    font-size: 13px;
    margin: 0;
    padding: 0;
    font-weight: 600;
}

.session .unanswered-question-container.selected h4,
.session .answered-question-container.selected h4 {
    background-color: #2093ff;
    padding: 4px 8px;
    border-radius: 16px;
}

.session .answered-question-container {
    left: 50%;
}

/* Recording UI *//* Recording UI *//* Recording UI *//* Recording UI *//* Recording UI */
/* Recording UI *//* Recording UI *//* Recording UI *//* Recording UI *//* Recording UI */
/* Recording UI *//* Recording UI *//* Recording UI *//* Recording UI *//* Recording UI */
.session .recording-ui {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.session .recording-ui .record-button-container {
    position: relative;
    width: 100%;
    height: 200px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.session .recording-ui .record-button-container .record-botton.outer-layer {
    position: relative;
    display: grid;
    place-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #ff3a208b;
    overflow: hidden;
}

.session .recording-ui .record-button-container .record-button-inner-layer {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #ff3a20;
    pointer-events: none;
    transition: 0.8s ease-in-out;
    overflow: hidden;
}

.session .recording-ui .record-button-container .record-botton.outer-layer.start .record-button-inner-layer {
    border-radius: 25%;
    animation-name: recording-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes recording-animation {
    0%   {
        transform: scale(100%);
    }

    50%  {
        transform: scale(50%)
    }

    100% {
        transform: scale(100%);
    }
}

.session .chart-container {
    width: calc(100% - 20px);
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: 50px;
    /* background-color: red; */
}



audio:first-of-type {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000000000000000000;
}

audio {
    position: absolute;
    top: 0;
    left: 300px;
    z-index: 100000000000000000000;
}