.softwarePage-color-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    background-color: black;
}

.softwarePage {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.softwarePage .software-page-vstack {
    position: relative;
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.softwarePage .software-page-vstack .content-view {
    position: relative;
    margin-top: 70px;
    margin-bottom: auto;
    width: calc(100% - 60px);
    padding: 30px;
    height: auto;
    color: white;
}

.softwarePage .content-view .title-container {
    position: relative;
    margin-bottom: 60px;
    font-size: 28px;
    width: 100%;
    display: grid;
    place-items: center;
}

.softwarePage .content-view .example-item {
    position: relative;
    height: fit-content;
    width: calc(100%);
    border-radius: 30px;
    display: grid;
    place-items: center;
}

.softwarePage .content-view .example-item h2 {
    position: relative;
    margin-bottom: 12px;
    font-size: 20px;
}

.softwarePage .content-view .example-item label {
    position: relative;
}

.softwarePage .content-view .example-item img {
    position: relative;
    margin-top: 12px;
}