.ask-questions-item {
    position: relative;
    height: auto;
    width: calc(100% - 80px);
    background-color: white;
    --item-question-padding: 40px;
    padding: 0; /* var(--item-question-padding); */
    /* box-shadow: 0 0 3px 2px rgb(0, 0, 0, 0.1); */
    border-radius: 20px;
    border-top-left-radius: 0;
    margin-left: 20px;
}

.ask-questions-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: calc( var(--question-text-are-height) - ( 2 * var(--item-question-padding) ) );
}

.ask-questions-item  .ask-questions-container textarea {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 8px;
    resize: none;
    border: 1.5px solid rgb(0, 0, 0, 0.35);
    border-radius: 12px;
}

.ask-questions-item  .ask-questions-container textarea:focus {
    border: 2px solid #1b90fe89;
}

.ask-questions-item  .ask-questions-container button {
    padding: 12px 18px;
    border-radius: 12px;
    background-color: #1b90fe;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
}