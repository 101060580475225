.question-item {
    width: fit-content;
    max-width: 100%;
    height: fit-content;
    padding: 4px 12px;
    /* background-color: rgba(255, 255, 255, 0.5); */
    background-color: #1b90fe1e;
    color: black;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 30px 20px;
}

.question-item:first {
    margin-top: 0;
}

.question-item:last-of-type {
    margin-bottom: 20px;
}

.question-item h4 {
    max-width: 100%;
    font-size: 13px;
    height: fit-content;
    width: fit-content;
    margin-left: 12px
}

.rounded-checkbox {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid rgba(0, 0, 0, 0.5);
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }

  .rounded-checkbox:checked {
    appearance: auto;
    clip-path: circle(50% at 50% 50%);
    background-color: #2093ff;
  }