.main {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.main-scrollable-container {
  position: relative;
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
