.landing-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: black;
    overflow: hidden;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 0;
    background-color: var(--app-blue);
}

.landing-background-image-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: lightgray;
    object-fit: cover;
    opacity: 75%;
    filter: blur(40px);
    backdrop-filter: blur(40px);
}

.landing-background-image {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: lightgray;
    object-fit: cover;
    opacity: 75%;
    box-shadow: 0 0 3px 0.5px rgba(0, 0, 0, 0.25);
}

.landing-content-container {
    position: absolute;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    /* place-items: center; */
    /* background-color: red; */
    background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.85));
}

.landing-ui-image-container {
    position: relative;
    width: auto;
    height: 700px;
    border-radius: 30px;
    margin-left: 60px;
    background-color: #2093ff2e;
    /* var(--app-blue); */
}

.landing-ui-image-container .landing-ui-image {
    position: relative;
    width: auto;
    height: 100%;
    object-fit: contain;
    border-radius: 30px;
    /* background-color: var(--app-blue); */
    mix-blend-mode: overlay;
    /* background-blend-mode: multiply; */
}

.landing-ui-image-container .landing-ui-image.top {
    position: absolute;
    width: auto;
    height: 100%;
    object-fit: contain;
    border-radius: 20px;
    mix-blend-mode: color;
    top: 0;
    left: 0;
    opacity: 0.7;
}

.landing-content-conter-v-stack {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    color: white;
    margin-left: 70px;
}

.landing-title {
    font-size: 60px;
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
}

.landing-purpose {
    font-size: 20px;
    max-width: 600px;
    margin: 0;
    opacity: 0.75;
    margin-top: 40px;
}

.landing-action-button {
    margin-top: 40px;
    width: fit-content;
    padding: 16px 24px;
    border: none;
    background-color: var(--app-blue);
    color: white;
    font-weight: 700;
    font-size: 20px;
    border-radius: 12px;
}

.diagonal-style-slant {
    position: absolute;
    z-index: 100000000000;
    bottom: -20%;
    left: 0;
    height: 40%;
    width: 150vw;
    background-color: black;
    transform: rotate(-8deg);
}
