.mathMode.cacl-limit {
    position: relative; 
    display: flex; 
    flex-direction: column; 
    width: fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
}

.mathMode.oneRowWrap {
    position: relative;
    display: inline-block;
    flex-direction: row;
    height: auto;
    width: calc(100% - 40px);
    /* display: flex; flex-direction: row;flex-wrap: wrap; */
}

.mathMode.math-fraction {
    position: relative;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
}

.mathMode.math-fraction numerator {
    position: relative;
    border-bottom: 2px solid black;
    padding-bottom: 4px;
    text-align: center;
}


.mathMode.math-fraction denominator {
    position: relative;
    padding-bottom: 12px;
    margin-top: 4px;
    text-align: center;
}