
.dashboard .logo-and-app-title {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    /* border-bottom: 2px solid rgb(56, 56, 56); */
    padding-bottom: 12px;
}

.dashboard .logo-container {
    position: relative;
    width: 34px;
    height: 34px;
    border-radius: 10px;
    background-color: white;
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
    margin-left: 8px;
    /*  */
    width: 0;
    height: 0;
    margin-left: 0;
}

.dashboard .logo-image {
    position: relative;
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.dashboard .appliction-title {
    color: white;
    font-size: 22px;
    letter-spacing: 1px;
    font-family: 'barlow', cursive;
    font-weight: 700;
    text-shadow: 0px 0.5px rgb(0, 0, 0, 0.5);
}

.dashboard .username-and-userprofile-img-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
}

.dashboard .user-profile-img-container {
    position: relative;
    width: 44px;
    height: 44px;
    border-radius: 35px;
    background-color: white;
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
}

.dashboard .user-profile-image {
    position: relative;
    object-fit: contain;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: gray;
}

.dashboard .username-label {
    color: gray;
    font-size: 12px;
    font-family: 'comfortaa-bold', cursive;
    font-weight: 700;
    text-shadow: 0px 0.5px rgb(0, 0, 0, 0.5);
    margin-top: 12px;
}