.settings-view {
    position: absolute;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: 0.3s ease-in-out;
    display: grid;
    place-items: center;
    z-index: 2200000000;
    --setting-items-color: rgba(166, 222, 238, 0.1);
}

.settings-view .hideSettingsView-view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(5px);
    transition: 0.3s ease-in-out;
}

.settings-view .content-and-color-ui-view {
    position: absolute;
    height: calc(100vh - 80px);
    width: calc(55vw - 60px);
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
    z-index: 0;
    overflow: hidden;
}

.settings-view .content-view {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh - 80px);
    width: calc(55vw - 60px);
    background-color: rgba(255, 255, 255, 0.5);
    overflow: hidden;
    overflow-y: scroll;
    z-index: 1000000;
}

.settings-view .content-view-scroll {
    position: relative;
    height: fit-content;
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    padding: 30px;
}

.settings-view .content-view-scroll .field-options {
    position: relative;
    display: flex;
    flex-direction: column;
}

.settings-view .profile-image-user-name {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.settings-view .new-profile-image-button {
    position: relative;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    border-radius: 75px;
    overflow: hidden;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: grid;
    place-items: center;
    border: 1px solid black;
}

.settings-view .new-profile-image-button .user-profile-image {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0.5;
}

.settings-view .new-profile-image-button .plus-icon {
    position: absolute;
    width: 44px;
    height: 44px;
    z-index: 1000000;
    display: grid;
    place-items: center;
    margin-bottom: 12px;
    margin-top: 12px;
    pointer-events: none;
}

.settings-view .new-profile-image-button .horizontal-plus {
    position: absolute;
    width: 40px;
    height: 6px;
    background-color: white;
    border-radius: 2.25px;
}

.settings-view .new-profile-image-button .vertical-plus {
    position: absolute;
    z-index: 1;
    height: 40px;
    width: 5.5px;
    border-radius: 2.25px;
    background-color: white;
}

.settings-view .username-container {
    position: relative;
    margin-left: 40px;
    font-size: 24px;
}



/*  */
.settings-view .custom-name-container,
.settings-view .major-field-container,
.settings-view .bio-field-container,
.settings-view .allow-direct-messages,
.settings-view .update-password-form-container
{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    background-color: var(--setting-items-color);
    padding: 12px 16px;
    border-radius: 8px;
}

.settings-view .custom-name-container label.title,
.settings-view .major-field-container label.title,
.settings-view .bio-field-container label.title,
.settings-view .allow-direct-messages label.title,
.settings-view .update-password-form-container label.title
{
    position: relative;
    font-size: 18px;
    color: black;
    font-weight: 600;
}

.settings-view .field-options label.description {
    position: relative;
    width: fit-content;
    height: fit-content;
    color: gray;
    font-size: 12px;
    margin-bottom: 8px;
    margin-top: 4px;
}

.settings-view .custom-name-container input,
.settings-view .major-field-container input,
.settings-view .bio-field-container textarea,
.settings-view .update-password-form-container .password-item input
{
    position: relative;
    height: 30px;
    width: 200px;
    margin-top: 8px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.7);
    font-size: 14px;
    color: black;
    padding: 4px 8px;
    font-family: 'barlow';
    border: none;
    background-color: rgba(0, 0, 0, 0.1);
}

/* Custom Name */
/* Custom Name */
/* Custom Name */
/* Custom Name */
.settings-view .custom-name-container {
    position: relative;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
}

/* Bio */
/* Bio */
/* Bio */
.settings-view .bio-field-container textarea {
    width: calc(100% - 24px);
    height: calc(200px - 16px);
    padding: 8px 12px;
    resize: none;
}


/* Direct messages */
/* Direct messages */
/* Direct messages */
/* Direct messages */
/* Direct messages */
/* Direct messages */
.settings-view .allow-direct-messages {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    padding: 20px;
    border-radius: 12px;
}


.settings-view .allow-direct-messages input {
    width: 24px;
    height: 24px;
}

/* Password */
/* Password */
/* Password */
/* Password */
.settings-view .update-password-form-container {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.settings-view .update-password-form-container .password-item {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin-top: 20px;
    display: grid;
    grid-template-rows: auto 1fr;
}

.settings-view .update-password-form-container .password-item input {
    width: 250px;
    margin-top: 8px;
}

.settings-view .update-password-form-container .password-item:first-of-type {
    margin-top: 0;
}

.settings-view .update-password-form-container .pass-field {
    position: relative;
    width: fit-content;
    height: fit-content;
    font-weight: 500;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.85);
}


/* Save Button Styles */
/* Save Button Styles */
/* Save Button Styles */
/* Save Button Styles */
/* Save Button Styles */
/* Save Button Styles */
.settings-view .save-button {
    position: relative;
    width: fit-content;
    height: fit-content;
    padding: 8px 20px;
    background-color: var(--app-blue);
    color: white;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    margin-top: 12px;
}

.settings-view .save-button.save-all-button {
    margin-left: auto;
    background-color: none;
    background: linear-gradient(#2093ff, #1e8af0);
    font-size: 16px;
    padding: 12px 24px;
}