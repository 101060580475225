.faq-button {
    position: fixed;
    bottom: 35px;
    right: 20px;
    z-index: 100000000;
    background-color: var(--app-blue);
    width: 70px;
    height: 70px;
    display: grid;
    place-items: center;
    color: white;
    font-family: 'comfortaa-bold', cursive;
    border-radius: 40px;
    box-shadow: 0 0 8px 2px #186fc087;
}

.dashboard-faq-button h1 {
    top: -10px;
}