.home.header-container {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  height: var(--header-height);
  background: linear-gradient(rgba(255, 255, 255, 0.18), rgba(255, 255, 255, 0));
  z-index: 10000;
  z-index: 10000000000000000000000000000000000000000000000000;
  font-family: 'roboto';
}

.home .logo-and-app-title {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home .logo-container {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-left: 60px;
  /* background-color: gray; */
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
  /* box-shadow: 0 0 3px 0.5px rgba(0, 0, 0, 0.15); */
}

.home .logo-image {
  position: relative;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.home.header-container .appliction-title {
  color: black;
  font-size: 22px;
  margin-left: 30px;
  font-family: 'barlow', cursive;
  font-weight: 700;
}

.home .header-nav-options {
  position: relative;
  right: 40px;
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-left: auto;
}
  
.home .header-nav-options li {
  margin-left: 30px;
  color: black;
  text-shadow: 0px 0.5px rgb(0, 0, 0, 0.5);
  font-family: 'comfortaa-bold', cursive;
}

.home .header-nav-options li a {
  color: black;
}

.home .header-nav-options li:hover {
  text-decoration: underline;
}

.home .header-buttons {
  font-size: 12px;
  height: fit-content;
  width: fit-content;
  font-weight: 600;
  background: black;
  color: white;
  padding: 8px 12px;
  border-radius: 20px;
  margin-right: 12px;
}

.home .header-buttons a {
  text-decoration: none;
  color: white;
}

.home .sign-in-up {
  background-color: var(--app-blue);
  padding: 10px 14px;
  border-radius: 12px;
  margin-left: 12px;
  margin-right: 30px;
  /* box-shadow: 0 0 8px 2px #0f4a825c; */
}