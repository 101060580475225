
html, body {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  /*  */
  --header-height: 70px;
  --app-blue: #2093ff;
  --app-yellow: #ffcf20;
  --app-red: #fc4421;
}

/* Removing Link Styles */
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }


h1, h2, h3, h4, h5, h6, p, label, a, span{
  margin: 0;
  padding: 0;
  font-family: 'Roboto';
}


/* hide scrollbar but allow scrolling */
.hideScrollIndicator {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

.hideScrollIndicator::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}