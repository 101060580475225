.note-controller-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;
    --text-area-height: 200px;
}

.note-controller-container .note-controls {
    position: relative;
    height: 100%;
    width: 100%;
}

.note-controller-container .note-content-view {
    height: calc(100% - var(--text-area-height));
    width: 100%;
    /* background-color: red; */
    overflow: hidden;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: 1fr 170px;
}

.note-controller-container .note-content-view .note-content {
    /* background-color: blue; */
    margin-left: 20px;
}

.note-controller-container .mode-items-container {
    position: relative;
    display: grid;
    place-items: center;
    /* width: 150px; */
    height: calc(100% - 20px);
    /* background-color: red; */
    margin-right: 20px;
    margin-bottom: 20px;
}

.note-controller-container .mode-items-container .mode-items-v-stack-grid {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* grid-template-rows: repeat(30px); */
}

.note-controller-container .mode-items-container .mode-item {
    display: grid;
    place-items: center;
    color: black;
    text-align: center;
    word-spacing: 1em;
    /* background-color: var(--app-blue); */
    background-color: white;
    height: 80px;
    margin-top: 20px;
    border-radius: 12px;
    box-shadow: 0 0 7px 1px rgb(0, 0, 0, 0.09);
    transition: 0.3s ease-in-out;
}

.note-controller-container .mode-items-container .mode-item.active {
    border: 2.5px solid black;
    height: 77.5px;
    box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.15);
}

.note-controls-buttons-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    margin-bottom: 20px;

}

.note-controls-buttons-container .notes-symbol-option-buttons {
    position: relative;
    display: inline-block;
    flex-direction: row;
    height: auto;
    width: calc(100% - 40px);
}

.note-controller-container .note-text-area-container {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
}


.note-controller-container .note-text-area-container textarea {
    position: relative;
    height: 100px;
    width: calc(100% - 12px - 70px);
    resize: none;
    border: none;
    border-radius: 14px;
    box-shadow: 0 0 8px 2px rgb(0, 0, 0, 0.11);
    padding: 20px;
    font-size: 14px;
}

.notes-symbol-buttons {
    position: relative;
    width: 30px;
    height: 30px;
    background-color: white;
    border: none;
    border-radius: 3px;
    box-shadow: 0 0 4px 1px rgb(0, 0, 0, 0.08);
    font-size: 18px;
    font-weight: 700;
    color: black;
    margin-left: 14px;
    margin-top: 14px;
    /* border: 1px solid gray; */
}

.notes-symbol-buttons:first-of-type {
    margin-left: 0;
}