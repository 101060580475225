.user-authentication-page {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: white;
    background: linear-gradient(white, rgb(90, 90, 90));
    display: flex;
    flex-direction: row;
    /* background: white; */
    /* background: linear-gradient(rgb(255, 207, 32, 0.4), rgba(32, 147, 255, 0.4)); */
    background: linear-gradient(white, rgba(32, 147, 255, 0.4));
}

.user-authentication-page-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: url('../public/pexels-yan-krukau-8199166.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    opacity: 0.15;
    filter: blur(60px);
}

.user-authentication-story-board {
    position: absolute;
    width: 100vw;
    height: 100vh;
    width: auto;
    display: flex;
    flex-direction: row;
}

.auth-container-left {
    position: relative;
    --left-width: 55vw;
    width: var(--left-width);
    height: calc(100%);
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    /* Border T */
    /* border-top-right-radius: 12px;
    border-bottom-right-radius: 12px; */
    overflow: hidden;
    /*  */
    box-shadow: 0 0 8px 4px rgb(0, 0, 0, 0.1);
}

.auth-diagnol-ui {
    position: absolute;
    bottom: -20vw;
    background-color: black;
    width: 100vw;
    height: 40vh;
    transform: rotate(-30deg);
    filter: blur(0.25px);
}

.auth-container-right {
    position: relative;
    width: 45vw;
    height: calc(100%);
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-container-right .auth-right-greetings-container {
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    color: black;
}

.auth-right-greetings-container h4 {
    font-size: 40px;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    font-family: barlow;
}

.auth-right-greetings-container h5 {
    font-size: 20px;
    opacity: 0.55;
    margin: 0;
    padding: 0;
    font-weight: 500;
}

.auth-right-greetings-container h5:last-of-type {
    margin-bottom: 60px;
}

.auth-icon-container {
    position: absolute;
    bottom: -100px;
    width: calc(100% - 40px);
    height: 400px;
    display: flex;
    flex-direction: row;
}

.auth-icon-container img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
    width: calc(100% / 6);
}

.auth-icon-container .y_hand_icon {
    transform: translateY(100px);
}

/* auth-options-container */
/* auth-options-container */
/* auth-options-container */
/* auth-options-container */
/* auth-options-container */
/* auth-options-container */
/* auth-options-container */
.auth-options-container {
    position: relative;
    width: auto;
    height: auto;
}

.auth-options {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.auth-greeting {
    position: relative;
    display: flex;
    flex-direction: column;

}

.auth-option-item {
    margin-top: 20px;
}

.auth-option-item:first-of-type {
    margin-top: 0;
}

.auth-option-item button {
    position: relative;
    border: none;
    background-color: var(--app-blue);
    padding: 16px 20px;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 700;
    color: white;
}

.auth-option-item.student button {}

.auth-option-item.professor button {
    background-color: black;
}


.auth-admin-option button {
    position: relative;
    border: none;
    background-color: transparent;
    padding: 8px 12px;
    margin: 40px;
    text-decoration: underline;
    font-size: 18px;
    font-weight: 600;
}





/* AuthFormContainer */
/* AuthFormContainer */
/* AuthFormContainer */
/* AuthFormContainer */
/* AuthFormContainer */
/* AuthFormContainer */
/* AuthFormContainer */
/* AuthFormContainer */
.Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 100;
}

.Auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding: 60px 40px;
    border-radius: 8px;
    background-color: white;
}

.Auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
}

.Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 28px;
    color: rgb(34, 34, 34);
    font-weight: 800;
}
  
.form-group {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.form-group label {
    margin-bottom: 12px;
}

.form-group:first-of-type {
    margin-top: 30px;
}

.form-group input {
    position: relative;
    border: 1px solid lightgray;
    height: 34px;
    border-radius: 8px;
    padding-left: 12px;
    font-size: 16px;
}


.Auth-form-container [type="submit"] {
    position: relative;
    border: none;
    background-color: var(--app-blue);
    padding: 12px 20px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 700;
    color: white;
}

.auth-instructions-label {
    font-size: 14px;
    font-weight: 500;
    color: gray;
    margin-bottom: 20px;
}

