.content-container {
    position: relative;
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: black;
    z-index: 0;
}

.content-container .content-info-container {
    position: relative;
    width: 100vw;
    height: 300px;
    background-color: black;
}

.content-container .content-semi-circle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: #115493ac;
    opacity: 0.8;
}

.content-container .content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000000;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content-container .hstack-content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.content-container .content-item {
    position: relative;
    width: 200px;
    height: auto;
    text-align: center;
    font-size: 18px;
    margin-left: 100px;
}

.content-container .content-item a {
    text-decoration: 2px underline;
    color: inherit;
    font-weight: 700;
}

.content-container .content-item:first-of-type {
    margin-left: 0;
}

.content-container-item {
    position: relative;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,1));
}
