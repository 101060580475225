.notes-dash-view-conatainer  {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    margin: 0;
    padding: 0;
    overflow: hidden;
    overflow-y: scroll;
}

.notes-dash-view-conatainer .notes-main-vstack {
    position: relative;
    display: flex;
    flex-direction: column;
    --notes-padding: 30px;
    --notes-view-title-height: 60px;
    padding: 20px;
}

.notes-dash-view-conatainer .notes-view-title {
    position: relative;
    width: auto;
    height: var(--notes-view-title-height);
    font-size: 34px;
}









.notes-courses-container {
    position: relative;
    height: calc(100vh - var(--notes-view-title-height) - var(--notes-padding) - var(--notes-padding) - 40px);
    padding: 12px;
    background-color: black;
    /* background-color: rgba(211, 211, 211, 0.8); */
    width: 180px;
    border-radius: 14px;
}

.notes-courses-container .notes-courses-options {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000000;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
}

.notes-courses-container .notes-courses-options .course-option-container {
    position: relative;
    width: 100%;
    height: calc(100% - 36px);
    max-height: 128px;
    max-width: 168px;
}

.notes-courses-options .course-option-container .course-option {
    position: relative;
    width: calc(100% - 36px);
    height: calc(100% - 36px);
    max-height: 128px;
    max-width: calc(100% - 36px);;
    border: none;
    margin-bottom: 20px;
    font-size: 17px;
    padding: 12px 18px;
    color: white;
    font-weight: 700;
    background-color: transparent;
    /*  */
    background-color: rgba(255, 255, 255, 0.122);
    margin-top: 18px;
    margin-left: 18px;
    border-radius: 8px;
    /* border-bottom: 1px solid gray; */
}



/* Header Card */
/* Header Card */
/* Header Card */
.notes-dash-view-conatainer .notes-controls-hstack {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
}

.notes-dash-view-conatainer .header-carder {
    position: relative;
    width: calc(100% - 60px - 20px - 150px);
    height: 150px;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 0 8px 4px rgba( 0, 0, 0, 0.07 );
    border: 2px solid rgba(0, 0, 0, 0.08);
    margin-left: 30px;
    margin-top: 30px;
    background-color: black;
    color: white;
}

.notes-dash-view-conatainer .header-title-desciption-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 100;
    padding: 30px;
}

.notes-dash-view-conatainer .view-title {
    position: relative;
    margin-bottom: 12px;
}

/* notes-controls-hstack */
/* notes-controls-hstack */
/* notes-controls-hstack */
/* notes-controls-hstack */
/* notes-controls-hstack */
/* notes-controls-hstack */
/* notes-controls-hstack */
/* notes-controls-hstack */
/* notes-controls-hstack */
.notes-dash-view-conatainer .new-note-button {
    position: relative;
    width: 150px;
    height: 150px;
    background-color: var(--app-blue);
    margin-top: 30px;
    margin-left: 20px;
    border-radius: 20px;
    box-shadow: 0 0 8px 4px rgba( 0, 0, 0, 0.07 );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.notes-dash-view-conatainer .new-note-button .plus-icon {
    position: relative;
    display: grid;
    place-items: center;
    width: 44px;
    height: 44px;
    margin-bottom: 12px;
    margin-top: 12px;
}

.notes-dash-view-conatainer .new-note-button .horizontal-plus {
    position: absolute;
    width: 40px;
    height: 6px;
    background-color: white;
    border-radius: 2.25px;
}

.notes-dash-view-conatainer .new-note-button .vertical-plus {
    position: absolute;
    z-index: 1;
    height: 40px;
    width: 5.5px;
    border-radius: 2.25px;
    background-color: white;
}



/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
.notes-dash-view-conatainer .course-section-option-label-container {
    position: relative;
    padding: 0 30px;
    margin-top: 24px;
}

.notes-dash-view-conatainer .course-section-option-label {
    font-size: 24px;
}

.notes-dash-view-conatainer .course-options-container {
    position: relative;
    width: 100%;
    height: 40px;
    margin-top: 8px;
}

.notes-dash-view-conatainer .course-options-scrollview {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x:  scroll;
}

.notes-dash-view-conatainer .course-options-content-view {
    position: relative;
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 30px;
}

.notes-dash-view-conatainer .course-option {
    background-color: black;
    color: white;
    padding: 8px 16px;
    border-radius: 25px;
    margin-left: 10px;
}

/* Notes List */
/* Notes List */
/* Notes List */
/* Notes List */
/* Notes List */
/* Notes List */
/* Notes List */
/* Notes List */
/* Notes List */
/* Notes List */
/* Notes List */
/* Notes List */
.notes-dash-view-conatainer .notes-list-container {
    position: relative;
    width: calc( 100% );
    height: auto;
    overflow-y: visible;
    overflow-x: scroll;
    margin-top: 6px;
}

.notes-dash-view-conatainer .notes-list {
    position: relative;
    width: fit-content;
    height: auto;
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    overflow-y: visible;
    margin-top: 8px;
}

.notes-dash-view-conatainer .notes-item {
    position: relative;
    width: 300px;
    height: 400px;
    margin: 8px;
    border-radius: 15px;
    margin-left: 12px;
    z-index: 0;
    background-color: white;
    /* overflow: hidden; */
    box-shadow: 0 0 4px 2px rgba(0,0,0, 0.2);
}

.notes-dash-view-conatainer .notes-item:first-of-type {
    margin-left: 0px;
}

.notes-dash-view-conatainer .notes-item .notes-item-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    color: black;
    display: flex;
    align-items: flex-end;
    /* background: linear-gradient(rgba( 200, 200, 200, 0.5), rgba(255, 2555, 255, 0.5)); */
}

/* Note Bidner Container */
.notes-dash-view-conatainer .note-binder-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 200000000;
    width: fit-content;
    height: calc(100% - 40px);
    display: grid;
    place-items: center;
    transform: translateX(-50%);
}

/* Note Bidner Clips */
.notes-dash-view-conatainer .note-binders {
    position: relative;
    margin-top: 0;
    width: 8px;
    height: 4px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
}


.notes-dash-view-conatainer .notes-card {
    margin-top: auto;
}

.notes-card-container {
  position: relative;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  margin: 12px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.notes-card-container .card-content {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 12px;
}



.notes-card .course-title,
.notes-card .notes-index-title {
  margin-bottom: 8px;
}

.notes-card .course-title {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.70);
}

.notes-card .notes-index-title {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.85);
}

.notes-card .notes-title {
  font-size: 24px;
  margin-top: 4px;
}


.notes-dash-view-conatainer .selected-note-container {
    position: fixed;
    z-index: 1000000000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    top: 100vw;
    left: 0;
    transition: 0.5s ease-in-out;
    backdrop-filter: blur(40px);
}

.notes-dash-view-conatainer .selected-note-container.active { top: 0; }

.notes-dash-view-conatainer .close-note-view-button {
    position: absolute;
    top: 20px;
    right: 20px;
    height: fit-content;
    width: fit-content;
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    border-radius: 20px;
    z-index: 10000000;
    font-family: 'barlow';
    font-weight: 900;
    background-color: rgba(0, 0, 0, 0.2);
}


.notes-dash-view-conatainer .note-controller-container {
    background-color: rgba(255, 255, 255, 0.95);
    padding-top: 40px;
    height: calc(100% - 40px);
}
