.footer-container {
    position: relative;
    width: 100vw;
    height: 200px;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    background-color: black;
    color: white;
}

.footer-container-vert {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 40px;
}

.footer-container-vert.na-loc {
    margin-left: 60px;
    margin-right: auto;
}

.footer-container-vert.na-loc .footer-comp-name {
    margin-bottom: 30px;
    color: white;
    font-size: 40px;
}

.footer-container-vert .footer-comp-loc {}


.footer-container-vert .support-email {
    position: relative;
    color: white;
}

.footer-container-vert .support-email a {
    position: relative;
    color: white;
}