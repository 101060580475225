
.dashboard-side-menu .dashbord-side-menu-item-container {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-top: 20px;
}

.dashboard-side-menu .dashbord-side-menu-item-container .dashboard-side-menu-list-conatainer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    color: white !important;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.dashboard-side-menu .dashbord-side-menu-item-container .dashboard-side-menu-list-conatainer li {
    position: relative;
    margin-top: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    border-radius: 8px;
    overflow: hidden;
    transition: 0.2s ease-in-out;
}

.dashboard-side-menu .dashbord-side-menu-item-container .dashboard-side-menu-list-conatainer li.defered-items.first {
    margin-top: 30px;
}

.dashboard-side-menu .dashbord-side-menu-item-container .dashboard-side-menu-list-conatainer li:hover {
    background-color: rgba(255, 255, 255, 0.1);
}


.dashboard-side-menu .dashbord-side-menu-item-container .dashboard-side-menu-list-conatainer .dashboard-side-menu-item.active {
    background-color: rgba(255, 255, 255, 0.1);
}


.dashboard-side-menu .dashbord-side-menu-item-container .dashboard-side-menu-list-conatainer li:first-of-type {
    margin-top: 0 !important;
}

.dashboard-side-menu .dashbord-side-menu-item-container .dashboard-side-menu-list-conatainer li .icon-container {  
    position: relative;
    width: 22px;
    height: 23px;
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
    margin-bottom: 8px;
}

.dashboard-side-menu .dashbord-side-menu-item-container .dashboard-side-menu-list-conatainer li .icon-container img { 
    position: relative;
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.dashboard-side-menu .dashbord-side-menu-item-container .dashboard-side-menu-list-conatainer li.dashboard-side-menu-items .label-conatainer {}

.dashboard-side-menu .dashbord-side-menu-item-container .dashboard-side-menu-list-conatainer li .label-conatainer label {
    font-size: 12px;
    color: gray;
}

.style-selected-side-dash {
    position: absolute;
    right: 0;
    height: 8px;
    width: 4px;
    border-radius: 2px;
    background-color: white;
    transition: 0.3s ease-in-out;
}

.dashboard-side-menu-item.active .style-selected-side-dash {
    height: 55px;
}
