.CareersPage {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: white;
}

.CareersPage .content-view {
    position: relative;
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.CareersPage .header-buttons.sign-in-up {
    box-shadow: none !important;
}

.CareersPage .page-title-header {
    position: relative;
    margin-top: 70px;
    background-color: black;
    height: 200px;
    display: grid;
    place-items: center;
}

.CareersPage .page-title-header h1 {
    color: white;
    font-size:80px;
}

.CareersPage .landing-image-container {
    position: relative;
    width: 100%;
    height: 600px;
    /* height: fit-content; */
    background: url("./Images/workingPeople.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.CareersPage .landing-image {
    position: relative;
    width: 100%;
    height: 700px;
    object-fit: cover;
    object-position: top;
}

.CareersPage .content {
    position: relative;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: auto;
}