.collab {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.collab .collab-header {
    position: relative;
    width: 100%;
    height: 50px;
    background-color: white;
}

.collab .app-title-and-course {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.collab .collab-header .appliction-title {
    color: black;
    font-size: 28px;
    letter-spacing: 1px;
    font-family: 'Roboto', cursive;
    font-weight: 700;
    text-shadow: 0px 0.5px rgb(0, 0, 0, 0.5);
    margin-left: 30px;
    margin-top: 10px;
}

.collab .collab-header .explaination-label {
    margin-left: 20px;
    margin-right: 30px;
    margin-top: 10px;
}

.collab .collab-course-options-and-chat {
    position: relative;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: row;
    /* grid-template-columns:  minmax(320px, 320px) 1fr minmax(230px, 230px); */
}


/* Side Menu */
/* Side Menu */
/* Side Menu */
/* Side Menu */
/* Side Menu */
/* Side Menu */
/* Side Menu */
/* Side Menu */
/* Side Menu */
/* Side Menu */
.collab .collab-sidemenu {
    position: relative;
    width: 320px;
    height: 100%;
    background-color: transparent;
    z-index: 0;
}

.collab .collab-sidemenu-content {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    display: grid;
    --title-container-height: 40px;
    grid-template-rows: minmax(var(--title-container-height), var(--title-container-height)) 1fr;
}

.collab .collab-sidemenu .side-menu-courses-title-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 12px;
    overflow: hidden;
}

.collab .collab-sidemenu .chat-type {
    position: relative;
    width: calc(100% - 12px);
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    overflow: hidden;
    border-top-left-radius: 12px;
}

.collab .collab-sidemenu .chat-type .direct-message-option,
.collab .collab-sidemenu .chat-type .course-thread-option {
    position: relative;
    height: 100%;
    width: calc(100%);
    display: grid;
    place-items: center;
    transition: 0.3s ease-in-out;
}

.collab .collab-sidemenu .chat-type .direct-message-option.selected,
.collab .collab-sidemenu .chat-type .course-thread-option.selected {
  background-color: rgba(0, 0, 0, 0.15);
}

.collab .collab-sidemenu .chat-type .direct-message-option:hover,
.collab .collab-sidemenu .chat-type .course-thread-option:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.collab .collab-sidemenu .chat-type .course-thread-option {}
.collab .collab-sidemenu .chat-type .direct-message-option {}

.collab .collab-sidemenu .side-menu-courses-title {
    color: white(0, 0, 0, 0.7);
    font-size: 12px;
    padding: 8px 12px;
    border-radius: 20px;
    transition: 0.3s ease-in-out;
}

.collab .collab-sidemenu .course-options-container-scrollview {
    position: relative;
    margin-bottom: 12px;
    margin-left: 12px;
    width: calc(100% - 12px);
    overflow: hidden;
    overflow-y: scroll;
    border-bottom-left-radius: 12px;
    background-color: rgba(0, 0, 0, 0.05);
}

.collab .collab-sidemenu .course-options-content-view {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.collab .collab-sidemenu .course-item {
    position: relative;
    height: fit-content;
    max-width: 100%;
    padding: 20px 16px;
    margin-top: 4px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
}

.collab .collab-sidemenu .course-item:first-of-type {
    margin-top: 0;
}

.collab .collab-sidemenu .course-label {
    font-size: 16px;
    font-weight: 600;
    color: white;
    text-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.5);
}

.collab .collab-sidemenu .time-from-last-message {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
} 

.collab .collab-sidemenu .last-message {
    position: relative;
    margin-top: 12px;
    max-width: calc(100% - 0px);
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(255, 255, 255, 0.55);
    white-space: nowrap;
    font-size: 14px;
}


/* Chat Container */
/* Chat Container */
/* Chat Container */
/* Chat Container */
/* Chat Container */
/* Chat Container */
/* Chat Container */
/* Chat Container */
/* Chat Container */
.collab .collab-chat-container {
    position: relative;
    width: calc(100% - 320px - 230px);
    background-color: rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    grid-template-rows: 1fr 120px;
    padding: 8px;
}

.collab .collab-chat-container .chat-content-messeges-container {
    position: relative;
    height: calc(100% - 120px) !important;
    width: 100%;
}

.collab .collab-chat-container .chat-container-scrollview {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    overflow-x: hidden;
    overflow-y: scroll;
    overflow-anchor: auto;
}

.collab .collab-chat-container .chat-container-scrollview .chat-container-contentview {
    position: relative;
    height: fit-content;
    width: 100%;
    margin-bottom: auto;
}


.collab .collab-chat-container .chat-container-scrollview .chat-container-contentview .chat-time-session-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    margin-top: 12px;
}


.collab .collab-chat-container .chat-container-scrollview .chat-container-contentview .chat-time-session-container .chat-session-time {
    position: relative;
}

.collab .collab-chat-container .chat-container-scrollview .chat-container-contentview .message-item {
    position: relative;
    width: fit-content;
    /* border-radius: 22px; */
    max-width: 65%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 8px;
}

.collab .collab-chat-container .chat-container-scrollview .chat-container-contentview .message-item.this-user {
    margin-left: auto;
    margin-right: 12px;
}

.collab .collab-chat-container .chat-container-scrollview .chat-container-contentview .message-item.other-user {
    margin-left: 12px;
}

.collab .collab-chat-container .chat-container-scrollview .chat-container-contentview .message-item.this-user .message-container {
    padding: 8px 12px;
    background: linear-gradient(-90deg, rgb(250, 250, 250), white);
    color: black;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 12px;
}

.collab .collab-chat-container .chat-container-scrollview .chat-container-contentview .message-item.other-user .message-container {
    padding: 8px 12px;
    background: linear-gradient(90deg, #1f8ef5, var(--app-blue));
    color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}


.collab .collab-chat-container .chat-container-scrollview .chat-container-contentview .message-item .message-sender-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: auto;
    padding: 4px 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    font-size: 12px;
}

.collab .collab-chat-container .chat-container-scrollview .chat-container-contentview .message-item.this-user .message-sender-container {
    /* background: linear-gradient(-90deg, rgb(250, 250, 250), white); */
    background-color: rgba(255, 255, 255, 0.35);
    margin-left: auto;
    color: rgba(0, 0, 0, 0.7);
    border-top-left-radius: 4px;
}

.collab .collab-chat-container .chat-container-scrollview .chat-container-contentview .message-item.other-user .message-sender-container {
    /* background: linear-gradient(90deg, #1f8ef5, var(--app-blue)); */
    background-color: #1f8ef551;
    color: rgba(255, 255, 255, 0.7);
    border-top-right-radius: 4px;
}


.collab .collab-chat-container .chat-container-scrollview .chat-container-contentview .message-item .message-sender-container img {
    position: relative;
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 50%;
    opacity: 0.85;
}

.collab .collab-chat-container .chat-container-scrollview .chat-container-contentview .message-item.this-user .message-sender-container label {
    margin-left: 8px;
}


.collab .collab-chat-container .chat-container-scrollview .chat-container-contentview .message-item.other-user .message-sender-container img {
    margin-right: 8px;
}






.collab .chat-content-and-controls {
    position: relative;
    width: 100%;
    height: 120px;
    display: grid;
    grid-template-columns: 1fr 154px;
}

.collab .collab-chat-container .chat-box-container {
    position: relative;
    height: 100%;
    width: calc(100%);
}

.collab .collab-chat-container .chat-box-container .chat-box {
    position: relative;
    --chat-font-family: 'Roboto';
    --chat-font-font-size: 14px;
    margin-top: 4px;
    height: calc(100% - 24px); /* Includes Padding, 2px border, a little extra space, and top margin */
    max-height: 100px;
    overflow: hidden;
    overflow-y: scroll;
    width: calc(100% - 30px); /* Includes Padding, 2px border, and a little extra space */
    padding: 8px 12px;
    border: 2px solid var(--app-blue);
    border-radius: 12px;
    font-family: var(--chat-font-family);
    font-size: var(--chat-font-font-size);
    background-color: transparent;
    resize: none;
}

.collab .collab-chat-container .chat-box:empty:before {
    content: attr(placeholder);
    position: absolute;
    left: 0;
    color: #aaa;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    direction: ltr;
    padding: 0px 12px;
    font-family: var(--chat-font-family);
    font-size: var(--chat-font-font-size);
}

.collab .collab-chat-container .chat-box img {
    position: relative;
    width: fit-content;
    height: fit-content;
    max-height: 80px;
    object-fit: contain;
    border-radius: 15px;
}







.collab .collab-chat-container .send-and-media-mode-container {
    position: relative;
    width: calc(100% - 4px);
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 4px;
}

.collab .collab-chat-container .send-and-media-mode-container .media-mode {
    position: relative;
    width: 100%;
    height: calc(40% + 8px - 4px);
    margin-top: 4px;
    /*  */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*  */
    background-color: white;
    border-radius: 8px;
    font-size: 13px;
}

.collab .collab-chat-container .send-and-media-mode-container .media-mode.active {
    width: calc(100% - 4px);
    height: calc(40% - 4px - 4px);
    border: 2px solid var(--app-blue);
}

.collab .collab-chat-container .send-and-media-mode-container .send-button {
    position: relative;
    width: 100%;
    height: calc(60% - 12px);
    margin-top: 4px;
    /*  */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*  */
    color: white;
    background-color: var(--app-blue);
    border-radius: 8px;
    font-size: 13px;
}

.collab .collab-chat-container .send-and-media-mode-container .media-mode img,
.collab .collab-chat-container .send-and-media-mode-container .send-button img {
    position: relative;
    width: 20px;
    height: 20px;
}

.collab .collab-chat-container .send-and-media-mode-container .media-mode img {
    margin-right: 8px;
}

.collab .collab-chat-container .send-and-media-mode-container .send-button img {
    margin-bottom: 4px;
}


/* message-requests-and-users-list */
/* message-requests-and-users-list */
/* message-requests-and-users-list */
/* message-requests-and-users-list */
/* message-requests-and-users-list */
/* message-requests-and-users-list */
/* message-requests-and-users-list */
/* message-requests-and-users-list */
/* message-requests-and-users-list */
.collab  .message-requests-and-users-list {
    position: relative;
    width: 230px;
    display: flex;
    flex-direction: column;
    grid-template-rows: 1fr 1fr;
    margin-right: 12px;
    margin-bottom: 12px;
}

.collab .message-requests-and-users-list .direct-message-requests-container,
.collab .message-requests-and-users-list .peers-list-container {
    position: relative;
    height: calc(50%);
    width: 100%;
}

.collab .message-requests-and-users-list .direct-message-requests-container .direct-message-requests,
.collab .message-requests-and-users-list .peers-list-container .peers-list {
    position: relative;
    width: 100%;
    height: calc(100% - 8px);;
    color: white;    
    background-color: rgba(0, 0, 0, 0.95);
    overflow: hidden;
}

.collab .message-requests-and-users-list .direct-message-requests {
    border-top-right-radius: 12px;
    padding-top: 8px;
}

.collab  .message-requests-and-users-list .peers-list {
    border-bottom-right-radius: 12px;
    padding-top: 8px;
}

.collab .message-requests-and-users-list .message-requests-and-users-title {
    position: relative;
    margin-left: 8px;
}

.collab .message-requests-and-users-list .peer-item {
    position: relative;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    background-color: rgb(0, 0, 0, 0.25);
    margin-top: 7px;
    padding: 4px 0;
}

.collab .message-requests-and-users-list .peer-item .user-profile-image {
    position: relative;
    width: 24px;
    height: 24px;
    margin-left: 12px;
    margin-right: 8px;
    border-radius: 12px;
}

.collab .message-requests-and-users-list .peer-item .user-name {
    position: relative;
    font-size: 12px;
}

.collab .message-requests-and-users-list .peer-item .add-user-buttom {
    position: relative;
    width: 22px;
    height: 22px;
    background-color: var(--app-blue);
    margin-left: 8px;
    border-radius: 11px;
    display: grid;
    place-items: center;
    z-index: 0;
}

.collab .message-requests-and-users-list .peer-item .add-user-buttom .horizontal-plus {
    position: absolute;
    width: 11px;
    height: 2px;
    background-color: white;
}

.collab .message-requests-and-users-list .peer-item .add-user-buttom .vertical-plus {
    position: absolute;
    z-index: 1;
    height: 11px;
    width: 2px;
    background-color: white;
}