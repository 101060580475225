.pricingPage-color-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 0;
}

.pricingPage {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.pricingPage .pricing-page-vstack {
    position: relative;
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.pricingPage .pricing-page-vstack .content-view {
    position: relative;
    margin-top: 70px;
    margin-bottom: auto;
    width: calc(100% - 60px);
    padding: 30px;
    height: auto;
}

.pricingPage .content-view .title-container {
    position: relative;
    margin-bottom: 30px;
}

.pricingPage .pricing-model-views {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
}

.pricingPage .content-view .pricing-card {
    position: relative;
    width: 300px;
    height: 400px;
    border: 4px solid black;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 12px 2px rgb(0, 0, 0, 0.12);
    margin-left: 20px;
    transition: 0.3s;
}

.pricingPage .content-view .pricing-card:hover {
    box-shadow: rgba(0, 0, 0, 24%) 0px 0px 40px -8px, rgba(0, 0, 0, 20%) 0px 0px 25px -16px;
    transform: scale3d(1.03, 1.03, 1.08) translate3d(0.1rem, -0.25rem, 20rem);
}

.pricingPage .content-view .pricing-card:first-of-type {
    margin-left: 0;
}

.pricingPage .pricing-type {
    margin-bottom: 30px;
    margin-top: 20px;
}

.pricingPage .price {}

.pricingPage .pricing-per-unit {
    margin-bottom: 30px;
}

.pricingPage .price-description {}


.pricingPage .pricing-single-call-to-action {
    position: relative;
    width: fit-content;
    padding: 18px 24px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    background-color: var(--app-blue);
    border: none;
    border-radius: 20px;
    color: white;
    font-weight: 800;
}