.lecture-playback-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.lecture-playback-container .playback-title-container {
    position: relative;
    width: auto;
    height: auto;
}

.lecture-playback-container .playback-title {
    position: relative;
    font-family: "roboto";
    color: black;
    margin-top: 30px;
    margin-left: 30px;
}


/* Header Card */
/* Header Card */
/* Header Card */
.lecture-playback-container .header-carder {
    position: relative;
    width: calc(100% - 60px);
    height: 150px;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 0 8px 4px rgba( 0, 0, 0, 0.07 );
    border: 2px solid rgba(0, 0, 0, 0.08);
    margin-left: 30px;
    margin-top: 30px;
}

.lecture-playback-container .header-title-desciption-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 100;
    padding: 30px;
}

.lecture-playback-container .view-title {
    position: relative;
    margin-bottom: 12px;
}

/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
/* Course Options */
.lecture-playback-container .course-section-option-label-container {
    position: relative;
    padding: 0 30px;
    margin-top: 24px;
}

.lecture-playback-container .course-section-option-label {
    font-size: 24px;
}

.lecture-playback-container .course-options-container {
    position: relative;
    width: 100%;
    height: 40px;
    margin-top: 8px;
}

.lecture-playback-container .course-options-scrollview {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x:  scroll;
}

.lecture-playback-container .course-options-content-view {
    position: relative;
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 30px;
}

.lecture-playback-container .course-option {
    background-color: black;
    color: white;
    padding: 8px 16px;
    border-radius: 25px;
    margin-left: 10px;
}

/* Lecture Playback List */
/* Lecture Playback List */
/* Lecture Playback List */
/* Lecture Playback List */
/* Lecture Playback List */
/* Lecture Playback List */
/* Lecture Playback List */
/* Lecture Playback List */
/* Lecture Playback List */
.lecture-playback-container .playback-list-container {
    position: relative;
    width: calc( 100% );
    height: auto;
    overflow-y: visible;
    overflow-x: scroll;
    margin-top: 6px;
}

.lecture-playback-container .playback-list {
    position: relative;
    width: fit-content;
    height: auto;
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    overflow-y: visible;
    margin-top: 8px;
}

.lecture-playback-container .playback-item {
    position: relative;
    width: 300px;
    height: 400px;
    margin: 8px;
    border-radius: 12px;
    margin-left: 12px;
    z-index: 0;
    background-color: black;
    overflow: hidden;
    box-shadow: 0 0 4px 2px rgba(0,0,0, 0.2);
}

.lecture-playback-container .playback-item:first-of-type {
    margin-left: 0px;
}

.lecture-playback-container .playback-item .playback-item-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    color: white;
    display: flex;
    align-items: flex-end;
    /* background: linear-gradient(rgba( 200, 200, 200, 0.5), rgba(255, 2555, 255, 0.5)); */
}



.lecture-playback-container .lecture-playback-card {
    margin-top: auto;
}

.lecture-playback-card-container {
  position: relative;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  margin: 12px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.lecture-playback-card-container .card-content {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 12px;
}



.lecture-playback-card .course-title,
.lecture-playback-card .lecture-index-title {
  margin-bottom: 8px;
}

.lecture-playback-card .course-title {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.70);
}

.lecture-playback-card .lecture-index-title {
  font-size: 17px;
  color: rgba(255, 255, 255, 0.85);
}

.lecture-playback-card .lecture-title {
  font-size: 24px;
  margin-top: 4px;
}



.lecture-playback-container .play-icon-container {
    position: absolute;
    right: 40px;
    top: 40px;
    z-index: 3;
    width: 24px;
    height: 24px;
    opacity: 0.85;
    background-color: var(--app-red);
    border-radius: 19px;
    display: grid;
    place-items: center;
}

.lecture-playback-container .play-icon-container img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.lecture-playback-container .play-icon-container img {
    position: absolute;
    z-index: 1;
    width: 12px;
    height: 12px;
    margin-left: 4px;
    /* width: 100%;
    height: 100%; */
}




/* Lecture Player */
/* Lecture Player */
/* Lecture Player */
/* Lecture Player */
/* Lecture Player */
/* Lecture Player */
/* Lecture Player */
/* Lecture Player */
/* Lecture Player */
/* Lecture Player */
/* Lecture Player */
.lecture-playback-player-container {
    position: fixed;
    z-index: 100000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    top: 100vw;
    left: 0;
    transition: 0.5s ease-in-out;
    backdrop-filter: blur(40px);
    display: grid;
    place-items: center;
}

.lecture-playback-player-container.active { top: 0; }


.close-playback-controller-button {
    position: absolute;
    top: 50px;
    left: 50px;
    height: 80px;
    width: 80px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 30px;
    color: white;
    border-radius: 20px;
}

.lecture-playback-player-container .lecture-playback-playback-content {
    position: relative;
    color: white;
}

.lecture-playback-player-container .lplybck-course {
    position: relative;
}

.lecture-playback-player-container .lplybck-professor {
    position: relative;
    margin-top: 12px;
}

.lecture-playback-player-container .lplybck-lecture {
    position: relative;
    margin-top: 40px;
}